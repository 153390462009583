import type { ComponentType } from "react"

// Created by FramerOverrides.com - do not redistribute.

export function withGoBack(Component): ComponentType {
    return (props) => {
        const goBack = () => {
            window.history.back()
        }

        return <Component {...props} onClick={goBack} />
    }
}
